import { useQuery } from '@graphcommerce/graphql'
import { MoneyFragment } from '@graphcommerce/magento-store/Money.gql'
import { StoreConfigDocument } from '@graphcommerce/magento-store/StoreConfig.gql'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { useMemo } from 'react'

export type MoneyProps = MoneyFragment & {
  round?: boolean
  isSpecialPrice?: boolean
  isPromotionLabel?: boolean
  sx?: SxProps<Theme>
}

export function Money({
  value,
  isSpecialPrice = false,
  isPromotionLabel = false,
  sx = [],
}: MoneyProps) {
  const { data: config } = useQuery(StoreConfigDocument)
  const locale = config?.storeConfig?.locale

  const numberFormatter = useMemo(() => {
    if (!locale) return undefined

    return new Intl.NumberFormat(locale.replace('_', '-'), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [locale])

  if (!numberFormatter || !value) return null

  const price = numberFormatter.format(value).split(',')[0]
  const decimal = numberFormatter.format(value).split(',')[1]

  return (
    <Box
      sx={[
        {
          '& .price-symbols': {
            fontSize: '23px',
            display: 'flex',
            marginLeft: '1px',
            marginTop: '2px',
          },
          '& .price-dash': {
            position: 'relative',
            marginLeft: '-0.27em',
            bottom: '1px',
          },
        },

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {isSpecialPrice ? (
        <Box
          className='price'
          sx={(theme) => ({
            display: 'flex',
            fontSize: '55px',
            letterSpacing: '-0.050em',
            justifyContent: 'flex-end',
            lineHeight: 1,
            fontWeight: 700,
            color: theme.palette.primary.main,
          })}
        >
          <Box
            sx={{
              display: 'inline-block',
              justifyContent: 'flex-end',
            }}
          >
            {price}
          </Box>
          {decimal || !isPromotionLabel ? <span>.</span> : ''}
          <Box
            className='decimal'
            sx={{
              display: 'inline-block',
              '& sup': {
                fontSize: '0.636em',
                lineHeight: '0.764em',
                marginLeft: '-0.164em',
                position: 'relative',
                letterSpacing: '-0.032em',
                top: '0.17em',
              },
            }}
          >
            {decimal || !isPromotionLabel ? (
              <sup>{decimal || '00'}</sup>
            ) : (
              <Box className='price-symbols'>
                <Typography component='span' className='price-dot' />
                <Typography component='span' className='price-dash' />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          className='regular-price'
          sx={(theme) => ({
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            lineHeight: 1,
            textDecoration: 'line-through',
            position: 'relative',
            display: 'flex',
            '& sup': {
              verticalAlign: 'inherit',
              fontSize: 'inherit',
            },
          })}
        >
          <Box sx={{ display: 'flex' }}>
            {price}
            <Box className='price-dot'>.</Box>
            {/* eslint-disable-next-line no-nested-ternary */}
            {decimal ? (
              <sup>{decimal}</sup>
            ) : isPromotionLabel ? (
              <Box className='price-dash'>-</Box>
            ) : (
              <sup>00</sup>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

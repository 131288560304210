import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { Trans } from '@lingui/react'
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  DialogProps,
  Box,
  Typography,
} from '@mui/material'
import { FormEvent, MouseEventHandler, useState } from 'react'
import { CallADruggistQuery } from './MoreInformationDialog/CallADruggist.gql'
import { MoreInformationDialog } from './MoreInformationDialog/MoreInformationDialog'

export type MedicationWarning = {
  additionalInfo: string[] | undefined
  extraCarefulWith: string[] | undefined
  intakePregnancy: string[] | undefined
}

export type AddToCartDialogProps = {
  open: boolean
  name?: string | null
  qty_pieces?: string | null
  qty_unit?: string | null
  sku?: string | null
  isMedical?: boolean
  medicationWarning?: MedicationWarning
} & Partial<DialogProps> &
  CallADruggistQuery

export function AddToCartDialog(props: AddToCartDialogProps) {
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const {
    name,
    infoForm,
    callADruggist,
    medicationWarning,
    sku,
    qty_pieces,
    qty_unit,
    onSubmit,
    isMedical,
    ...dialogProps
  } = props

  const { setValue, handleSubmit } = useFormAddProductsToCart()
  const submit = handleSubmit(() => {})

  const handleAddToCart: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setValue(`cartItems.0.sku`, sku ?? '')
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit()
    onSubmit?.(e as unknown as FormEvent<HTMLDivElement>)
  }

  return (
    <>
      <Dialog
        {...dialogProps}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description-1 alert-dialog-description-2 alert-dialog-description-3'
      >
        <DialogContent sx={(theme) => ({ p: theme.spacings.sm, pb: 0 })}>
          <DialogContentText
            id='alert-dialog-description-3'
            sx={(theme) => ({ mt: theme.spacings.xs })}
          >
            {isMedical && (
              <>
                {medicationWarning?.extraCarefulWith &&
                  medicationWarning?.extraCarefulWith.length > 0 && (
                    <Box
                      sx={(theme) => ({
                        p: theme.spacings.sm,
                        my: theme.spacings.sm,
                        backgroundColor: 'background.default',
                      })}
                    >
                      {medicationWarning?.extraCarefulWith?.map((i, index) => (
                        <Typography variant={index === 0 ? 'h4' : 'body2'} sx={{ mb: '8px' }}>
                          {i}
                        </Typography>
                      ))}
                    </Box>
                  )}
                {medicationWarning?.intakePregnancy &&
                  medicationWarning?.intakePregnancy.length > 0 && (
                    <Box
                      sx={(theme) => ({
                        p: theme.spacings.sm,
                        my: theme.spacings.sm,
                        backgroundColor: 'background.default',
                      })}
                    >
                      {medicationWarning?.intakePregnancy?.map((i, index) => (
                        <Typography variant={index === 0 ? 'h4' : 'body2'} sx={{ mb: '8px' }}>
                          {i}
                        </Typography>
                      ))}
                    </Box>
                  )}
                {medicationWarning?.additionalInfo &&
                  medicationWarning?.additionalInfo.length > 0 && (
                    <Box
                      sx={(theme) => ({
                        p: theme.spacings.sm,
                        my: theme.spacings.sm,
                        backgroundColor: 'background.default',
                      })}
                    >
                      {medicationWarning?.additionalInfo?.map((i, index) => (
                        <Typography variant={index === 0 ? 'h4' : 'body2'} sx={{ mb: '8px' }}>
                          {i}
                        </Typography>
                      ))}
                    </Box>
                  )}
              </>
            )}

            <strong>
              <Trans id='Do you have a question about this product? Contact a druggist' />
            </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            p: theme.spacings.sm,
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          })}
        >
          <Button
            onClick={(e) => {
              setOpenInfoDialog(true)
              e.preventDefault()
              e.stopPropagation()
            }}
            autoFocus
            variant='text'
            color='primary'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                mb: '5px',
              },
            })}
          >
            <Trans id='Yes, more information' />
          </Button>
          <Button
            onClick={handleAddToCart}
            autoFocus
            variant='pill'
            color='primary'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                mb: '5px',
                ml: '0px !important',
              },
            })}
          >
            <Trans id='Add to Cart' />
          </Button>
        </DialogActions>
      </Dialog>
      <MoreInformationDialog
        name={name}
        openInfoDialog={openInfoDialog}
        setOpenInfoDialog={setOpenInfoDialog}
        infoForm={infoForm}
        callADruggist={callADruggist}
        onAddToCart={handleAddToCart}
      />
    </>
  )
}

import { Container } from '@mui/material'
import Script from 'next/script'
import { memo, useCallback } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'

export const RowFormsApp = memo((props: RowFormsAppFragment) => {
  const { identity } = props
  // @ts-expect-error external script used without types
  // eslint-disable-next-line new-cap
  const init = useCallback(() => new formsapp(identity), [identity])

  return (
    <Container>
      <div {...({ formsappid: identity } as any)} />
      <Script
        id={identity}
        strategy='afterInteractive'
        onReady={init}
        src='https://my.forms.app/static/iframe.js'
      />
    </Container>
  )
})

import {
  AddProductsToCartButton,
  ProductListItemProps,
  useAddProductsToCartAction,
} from '@graphcommerce/magento-product'
import { ButtonProps } from '@mui/material'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { Product } from '../../../lib/types'
import { AddToCartDialog } from '../../Dialogs/AddToCartDialog'
import { CallADruggistQuery } from '../../Dialogs/MoreInformationDialog/CallADruggist.gql'

type AddToCartButton = {
  product: Product | ProductListItemProps
  label: ReactNode
} & ButtonProps

export type AddProductsToCartModalProps = AddToCartButton & CallADruggistQuery

export function AddProductsToCartButtonWithModal(props: AddProductsToCartModalProps) {
  const { infoForm, callADruggist, product, label, ...buttonProps } = props
  const [open, setOpen] = useState(false)
  const action = useAddProductsToCartAction(props)
  const isMedical = Boolean(callADruggist && (product?.mos_rvh_code || product?.mos_rvg_code))

  const medicationWarning = {
    additionalInfo: product?.mos_overige_informatie?.split('\n'),
    extraCarefulWith: product?.mos_extra_voorzichtig_bij?.split('\n'),
    intakePregnancy: product?.mos_inname_zwangerschap_borstvoeding?.split('\n'),
  }

  if (callADruggist && (product?.mos_rvh_code || product?.mos_rvg_code)) {
    return (
      <>
        <AddProductsToCartButton
          {...buttonProps}
          color='primary'
          {...action}
          product={product}
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          {label}
        </AddProductsToCartButton>
        <AddToCartDialog
          infoForm={infoForm}
          callADruggist={callADruggist}
          medicationWarning={medicationWarning}
          open={open}
          sku={product.sku}
          onClose={(e: SyntheticEvent<unknown, Event>) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(false)
          }}
          onSubmit={() => setOpen(false)}
          qty_pieces={product.qty_pieces}
          qty_unit={product.qty_unit}
          isMedical={isMedical}
        />
      </>
    )
  }

  return (
    <AddProductsToCartButton {...buttonProps} product={product}>
      {label}
    </AddProductsToCartButton>
  )
}

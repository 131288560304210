import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

export function ProductPageAccordionItem(props) {
  const { title, children, expanded } = props
  return (
    <Accordion
      expanded={expanded}
      sx={(theme) => ({
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
        '&.MuiAccordion-root.Mui-expanded': {
          margin: 0,
        },
        '& .MuiAccordion-root.Mui-expanded::before': {
          opacity: 1,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
          minHeight: '48px',
        },
        padding: theme.spacings.md,
        boxShadow: 'none',
        borderRadius: '0 0 10px 10px !important',
        [theme.breakpoints.down('md')]: {
          borderRadius: '0 !important',
        },
      })}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          p: 0,
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center !important',
          },
        }}
      >
        <Typography variant='h4'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          p: 0,
          '& > div': {
            p: 0,
            gap: 0,
            mb: 0,
            pt: theme.spacings.xs,
            pb: theme.spacings.sm,
          },
          '& .MuiAccordionSummary-content': {
            mx: 0,
          },
          '& .MuiContainer-root': {
            p: '0 !important',
          },
          [theme.breakpoints.down('sm')]: {
            '& td.MuiBox-root': {
              display: 'block',
              pb: '0 !important',
            },
            '& tr td:first-of-type p': {
              mb: '0 !important',
            },
          },
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

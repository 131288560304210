import { ProductListQuery } from '@graphcommerce/magento-product'
import { Trans } from '@lingui/react'
import { Box } from '@mui/material'
import { CallADruggistQuery } from '../../Dialogs/MoreInformationDialog/CallADruggist.gql'

type MedicalLabelProps = {
  callADruggist: CallADruggistQuery['callADruggist']
  mos_rvh_code: NonNullable<
    NonNullable<NonNullable<ProductListQuery['products']>['items']>[0]
  >['mos_rvh_code']
  mos_rvg_code: NonNullable<
    NonNullable<NonNullable<ProductListQuery['products']>['items']>[0]
  >['mos_rvg_code']
}

export function MedicalLabel(props: MedicalLabelProps) {
  const { callADruggist, mos_rvh_code, mos_rvg_code } = props

  if (!callADruggist || (!mos_rvh_code && !mos_rvg_code)) return null

  return (
    <Box
      sx={(theme) => ({
        width: 'max-content',
        borderRadius: '3px',
        typography: theme.typography.caption,
        px: `calc(${theme.spacings.xxs} * 0.5)`,
        mb: `calc(${theme.spacings.xxs} * 0.5)`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.light,
        fontWeight: '300',
      })}
    >
      <Trans id='Medication' />
    </Box>
  )
}

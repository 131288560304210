import { ProductListItemRenderer } from '@graphcommerce/magento-product'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { SxProps } from '@mui/material'
import { CallADruggistQuery } from '../Dialogs/MoreInformationDialog/CallADruggist.gql'
import { MedicalLabel } from '../Product/MedicalLabel/MedicalLabel'
import { ProductListItem } from './ProductListItem'
import { ProductListItemConfigurable } from './ProductListItemConfigurable'

export function productListRenderer(dialogProps?: CallADruggistQuery): ProductListItemRenderer {
  const sxProps: SxProps = { padding: '5.5px' }
  return {
    SimpleProduct: (props) => (
      <ProductListItem
        {...props}
        {...dialogProps}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
        bottomLeft={
          <MedicalLabel
            callADruggist={dialogProps?.callADruggist}
            mos_rvg_code={props?.mos_rvg_code}
            mos_rvh_code={props?.mos_rvh_code}
          />
        }
      />
    ),
    ConfigurableProduct: (props) => (
      <ProductListItemConfigurable
        {...props}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
      />
    ),
    BundleProduct: (props) => (
      <ProductListItem
        {...props}
        {...dialogProps}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
      />
    ),
    VirtualProduct: (props) => (
      <ProductListItem
        {...props}
        {...dialogProps}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
      />
    ),
    DownloadableProduct: (props) => (
      <ProductListItem
        {...props}
        {...dialogProps}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
      />
    ),
    GroupedProduct: (props) => (
      <ProductListItem
        {...props}
        {...dialogProps}
        aspectRatio={[1, 1]}
        topRight={<ProductWishlistChip {...props} sx={sxProps} />}
      />
    ),
  }
}

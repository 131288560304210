import { useCartQuery } from '@graphcommerce/magento-cart'
import { CartPageDocument } from '@graphcommerce/magento-cart-checkout'

type UseGetCurrentProductQtyFromCartProps = {
  sku?: string | null
}

export function useGetCurrentProductQtyFromCart({ sku }: UseGetCurrentProductQtyFromCartProps) {
  const { data: cartData, loading } = useCartQuery(CartPageDocument, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const cartItemQty = cartData?.cart?.items?.find((item) => item?.product.sku === sku)?.quantity

  return {
    cartItemQty,
    cartData,
    loading,
  }
}

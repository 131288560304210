import { Box, styled } from '@mui/material'
import { Money } from '../Money'

const ProductLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: theme.palette.primary.contrastText,
  width: '50px',
  height: '50px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  fontSize: '13px',
  lineHeight: '1',
  position: 'absolute',
  left: 10,
  top: 10,
  fontWeight: 700,
  textAlign: 'center',
  textTransform: 'uppercase',

  '& .regular-price': {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 700,
    fontSize: '1.2em',

    '& sup': {
      verticalAlign: 'super',
      fontSize: 'smaller',
    },
  },
}))

export function PromotionLabel(props) {
  const { label_display, label_percent, label_price, label_qty, promotion_type, price_range } =
    props
  // Logging for communication between Tim and Framework
  const totalDiscount =
    label_percent && label_qty
      ? Math.round(label_percent * label_qty)
      : Math.round(label_percent * 1)
  const regularPrice = price_range?.minimum_price?.regular_price?.value

  const discountFinalPrice = regularPrice && label_qty ? regularPrice * label_qty : null

  switch (promotion_type) {
    case 'PERCENTAGE_DISCOUNT':
      if (label_qty && label_qty > 1) {
        if (totalDiscount % 100 === 0) {
          const amountForPromotion = totalDiscount / 100
          return (
            <ProductLabel className='promotionLabel PERCENTAGE_DISCOUNT_1'>
              <Box
                sx={{ fontWeight: 700, lineHeight: '1.1em', fontSize: '24px', marginTop: '-9px' }}
              >
                {label_qty - amountForPromotion}+{amountForPromotion}
              </Box>
              <Box sx={{ fontSize: '10px' }}>Gratis</Box>
            </ProductLabel>
          )
        }
        if (totalDiscount === 50) {
          return (
            <ProductLabel className='promotionLabel PERCENTAGE_DISCOUNT_2'>
              <Box
                textTransform='lowercase'
                sx={{ fontWeight: 700, lineHeight: '1em', fontSize: '22px', marginTop: '-12px' }}
              >
                {label_qty}
                <sup>e</sup>
              </Box>
              <Box sx={{ lineHeight: '1em', fontSize: '8.5px' }}>Halve</Box>
              <Box sx={{ lineHeight: '1em', fontSize: '10px' }}>Prijs</Box>
            </ProductLabel>
          )
        }

        return (
          <ProductLabel className='promotionLabel PERCENTAGE_DISCOUNT_3'>
            {totalDiscount > 100 ? (
              <>
                {label_qty}
                <sup>e</sup>
                <br />
                Voor {totalDiscount / label_qty}% Korting
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', fontSize: '19px', marginTop: '-4px' }}>
                  <Box>{totalDiscount}</Box>
                  <Box sx={{ fontSize: '16px' }}>%</Box>
                </Box>
                <Box sx={{ fontSize: '8.5px' }}>korting</Box>
              </>
            )}
          </ProductLabel>
        )
      }
      return (
        <ProductLabel className='promotionLabel SP_PERCENTAGE_DISCOUNT'>
          <Box sx={{ lineHeight: '1.1em', display: 'flex', fontSize: '21px', marginTop: '-6px' }}>
            <Box>{totalDiscount}</Box>
            <Box sx={{ lineHeight: '1.2em', fontSize: '18px' }}>%</Box>
          </Box>
          <Box sx={{ lineHeight: '1.1em', fontSize: '8.5px' }}>korting</Box>
        </ProductLabel>
      )
    case 'PERCENTAGE_DISCOUNT_CHEAPEST':
      return (
        <ProductLabel className='promotionLabel PERCENTAGE_DISCOUNT_CHEAPEST'>
          <Box
            sx={{
              fontSize: '21px',
              marginTop: '-8px',
              '& sup': {
                top: '0.2em',
                position: 'relative',
                textTransform: 'lowercase',
              },
            }}
          >
            {label_qty}
            <sup>e</sup>
          </Box>{' '}
          <Box sx={{ fontSize: '10px' }}>Gratis</Box>
        </ProductLabel>
      )
      break
    case 'FIXED_DISCOUNT':
      return (
        <ProductLabel
          className='promotionLabel FIXED_DISCOUNT'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '8.5px',

            '& .regular-price': { fontSize: '16px', marginTop: '-4px' },
          }}
        >
          <Money value={label_price} isPromotionLabel /> Korting
        </ProductLabel>
      )
      break
    case 'FIXED_PRICE':
      if (label_qty === 1) {
        return (
          <ProductLabel className='promotionLabel FIXED_PRICE_1'>
            Voor
            <Money value={label_price} isPromotionLabel />
          </ProductLabel>
        )
      }
      return (
        <ProductLabel className='promotionLabel FIXED_PRICE_2'>
          <Box sx={{ fontSize: '10px' }}>{label_qty} voor</Box>
          <Box>
            <Money value={label_price * label_qty} isPromotionLabel />
          </Box>
        </ProductLabel>
      )
      break
    case 'FIXED_PRICE_SET':
      return (
        <ProductLabel className='promotionLabel FIXED_PRICE_SET' sx={{ fontSize: '10px' }}>
          {label_qty} voor
          <Money
            value={label_price}
            isPromotionLabel
            sx={{
              '& .regular-price': {
                fontSize: '15px !important',
              },
            }}
          />
        </ProductLabel>
      )
      break
    case 'SP_PERCENTAGE_DISCOUNT':
      return (
        <ProductLabel className='promotionLabel SP_PERCENTAGE_DISCOUNT'>
          <Box sx={{ display: 'flex', fontSize: '19px', marginTop: '-4px' }}>
            <Box>{totalDiscount}</Box>
            <Box sx={{ fontSize: '16px' }}>%</Box>
          </Box>
          <Box sx={{ fontSize: '8.5px' }}>korting</Box>
        </ProductLabel>
      )
      break
    case 'SP_FIXED_DISCOUNT':
      if (!price_range) {
        break
      }

      if (label_display && label_display * 1 === discountFinalPrice) {
        return (
          <ProductLabel className='promotionLabel SP_FIXED_DISCOUNT_1'>
            Voor
            <Money value={label_price} isPromotionLabel />
          </ProductLabel>
        )
      }
      return (
        <ProductLabel
          className='promotionLabel SP_FIXED_DISCOUNT_2'
          sx={{
            '& .regular-price': { fontSize: '15px' },
            '& .regular-price sup': { top: '0.1em', position: 'relative' },
          }}
        >
          <Box sx={{ fontSize: '12px' }}>Voor</Box>
          <Money value={label_price} isPromotionLabel />
        </ProductLabel>
      )
      break
  }
  return null
}

import { ProductListPriceFragment } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice.gql'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Typography, TypographyProps, Box } from '@mui/material'
import { Money } from '../Product/Money'

const { classes, selectors } = extendableComponent('ProductListPrice', [
  'root',
  'discountPrice',
] as const)

type ProductListPriceProps = ProductListPriceFragment & Pick<TypographyProps, 'sx'>

export function ProductListPrice(props: ProductListPriceProps) {
  const { final_price, sx = [] } = props

  return (
    <Typography
      component='div'
      variant='body1'
      className={classes.root}
      sx={[{ margin: '-3px 4px auto auto' }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Money
        isSpecialPrice
        {...final_price}
        sx={{
          '& .price': { fontSize: '38px' },
          '& sup': {
            fontSize: '0.5em',
            top: '0.08em',
          },
        }}
      />
    </Typography>
  )
}

ProductListPrice.selectors = selectors
